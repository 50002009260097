import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Help } from '@material-ui/icons';
import _ from 'lodash';
import StorageService from 'common/services/storage';

import BannerSwipeablePicker from './BannerSwipeablePicker';
import TextEditor from './TextEditor';
import TransferList from './TransferList';

import { icons } from '../configs/iconSchema';
import { Typography } from '@material-ui/core';

const uploadByFile = async (file) => {
  const url = await StorageService.upload('/contents', file);
  return {
    success: 1,
    file: { url }
  };
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%'
  },
  dialogContent: {
    backgroundColor: theme.palette.grey[200]
  },
  imagePreview: {
    backgroundColor: theme.palette.common.black,
    backgroundImage: ({ url }) =>
      `url('${url ? url : 'https://via.placeholder.com/100'}')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginBottom: `${theme.spacing(1)}px`,
    height: '200px',
    width: '100%'
  }
}));

const ContentDialog = ({
  type,
  payload,
  open,
  onClose,
  onSave,
  contacts,
  links,
  appointments,
  posts,
  files,
  videos
}) => {
  const [block, setBlock] = useState({});
  const { dialog, dialogContent } = useStyles();
  useEffect(() => {
    if (open) {
      setBlock(payload.block);
    } else {
      setBlock({});
    }
  }, [open, payload.block]);

  const handleContentChange = (content) => {
    setBlock({ ...block, content });
  };

  const handleSettingChange = (name) => (e) => {
    setBlock({ ...block, [name]: e.target.checked });
  };

  const handleBlockChange = (data) => {
    console.log(data);
    setBlock({ ...block, ...data });
  };

  const renderEditArea = () => {
    if (!type) return null;
    switch (type.type) {
      case 'banner':
        return (
          <BannerPicker
            block={{ content: {}, ...block }}
            onChange={handleBlockChange}
          />
        );
      case 'bannerswipeable':
        return (
          <BannerSwipeablePicker
            block={{ ...block }}
            onChange={handleBlockChange}
          />
        );
      case 'text':
        return (
          <>
            <Box
              py={2}
              border={1}
              borderColor="grey.500"
              borderRadius={4}
              bgcolor="white"
            >
              <TextEditor
                data={block.content}
                onChange={handleContentChange}
                uploadByFile={uploadByFile}
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={block.asFaq}
                  onChange={handleSettingChange('asFaq')}
                />
              }
              label={<Typography variant="body2">แสดงแบบ FAQ</Typography>}
            />
          </>
        );
      case 'title':
        if (!block.content) {
          block.content = { icon: 'none', text: '' };
        }
        return (
          <Box display="flex">
            <SelectList
              label="ไอคอน"
              emptyValue="เลือก icon"
              value={block.content.icon || 'none'}
              onChange={(e) =>
                handleBlockChange({
                  content: { ...block.content, icon: e.target.value }
                })
              }
              items={icons}
            />
            <Box mr={2} />
            <TextField
              autoFocus
              label="ข้อความ"
              value={block.content.text || ''}
              onChange={(e) =>
                handleBlockChange({
                  content: { ...block.content, text: e.target.value }
                })
              }
              fullWidth
            />
          </Box>
        );
      case 'link':
        const linkContent = { icon: 'none', ...block.content };
        return (
          <>
            <Box display="flex" justifyItems="flex-end">
              <SelectList
                label="ไอคอน"
                emptyValue="เลือก icon"
                value={linkContent.icon || 'none'}
                onChange={(e) =>
                  handleBlockChange({
                    content: { ...linkContent, icon: e.target.value }
                  })
                }
                items={icons}
              />
              <Box mr={2} />
              <TextField
                fullWidth
                label="หัวข้อ"
                value={linkContent.title || ''}
                onChange={(e) =>
                  handleBlockChange({
                    content: { ...linkContent, title: e.target.value }
                  })
                }
              />
            </Box>
            <Box mb={2} />
            <SelectList
              label="หมวดหมู่"
              fullWidth={true}
              emptyValue="กรุณาเลือก category"
              value={linkContent.category || 'none'}
              onChange={(e) =>
                handleBlockChange({
                  content: { ...linkContent, category: e.target.value }
                })
              }
              items={links}
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={block.paginate}
                  onChange={handleSettingChange('paginate')}
                />
              }
              label={<Typography variant="body2">แสดงเป็นหน้า</Typography>}
            />
          </>
        );
      case 'appointment':
        return (
          <SelectList
            label="หมวดหมู่"
            fullWidth={true}
            emptyValue="กรุณาเลือก category"
            value={block.content || 'none'}
            onChange={(e) => handleContentChange(e.target.value)}
            items={appointments}
          />
        );
      case 'file':
        return (
          <SelectList
            label="หมวดหมู่"
            fullWidth={true}
            emptyValue="กรุณาเลือก category"
            value={block.content || 'none'}
            onChange={(e) => handleContentChange(e.target.value)}
            items={files}
          />
        );
      case 'post':
        return (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={block.allPost}
                  onChange={handleSettingChange('allPost')}
                />
              }
              label={<Typography variant="body2">แสดงทุกโพสต์</Typography>}
            />
            {!block.allPost && (
              <TransferList
                initialLeft={posts.filter((post) =>
                  block.content ? !block.content.includes(post.id) : true
                )}
                initialRight={
                  block.content
                    ? block.content.map((postId) =>
                        posts.find((post) => post.id === postId)
                      )
                    : []
                }
                onChange={(left, right) =>
                  handleContentChange(right.map((post) => post.id))
                }
              />
            )}
          </>
        );
      case 'contact':
      case 'mapwithcontact':
        const content = { icon: 'none', ...block.content };
        return (
          <>
            <ContactList
              content={content}
              onChange={handleBlockChange}
              contacts={contacts}
              icons={icons}
            />
            {/* <Box display="flex" justifyItems="flex-end">
              <SelectList
                emptyValue="เลือก icon"
                value={content.icon || 'none'}
                onChange={e =>
                  handleBlockChange({
                    content: { ...content, icon: e.target.value }
                  })
                }
                items={icons}
              />
              <Box mr={2} />
              <TextField
                fullWidth
                label="หัวข้อ"
                value={content.title || ''}
                onChange={e =>
                  handleBlockChange({
                    content: { ...content, title: e.target.value }
                  })
                }
              />
              <Box mr={2} />
              <TextField
                fullWidth
                label="คำอธิบาย"
                value={content.description || ''}
                onChange={e =>
                  handleBlockChange({
                    content: { ...content, description: e.target.value }
                  })
                }
              />
              <Box mr={2} />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={content.filter}
                    onChange={e =>
                      handleBlockChange({
                        content: { ...content, filter: e.target.checked }
                      })
                    }
                  />
                }
                label="Filter"
              />
            </Box>
            <Box mb={2} />
            <SelectList
              fullWidth={true}
              items={contacts}
              emptyValue="กรุณาเลือก category"
              value={content.category || 'none'}
              onChange={e =>
                handleBlockChange({
                  content: { ...content, category: e.target.value }
                })
              }
            />*/}
          </>
        );
      case 'video':
        const videoContent = { icon: 'none', ...block.content };
        return (
          <>
            <Box display="flex" justifyItems="flex-end">
              <SelectList
                label="ไอคอน"
                emptyValue="เลือก icon"
                value={videoContent.icon || 'none'}
                onChange={(e) =>
                  handleBlockChange({
                    content: { ...videoContent, icon: e.target.value }
                  })
                }
                items={icons}
              />
              <Box mr={2} />
              <TextField
                fullWidth
                label="หัวข้อ"
                value={videoContent.title || ''}
                onChange={(e) =>
                  handleBlockChange({
                    content: { ...videoContent, title: e.target.value }
                  })
                }
              />
            </Box>
            <Box mb={2} />
            <SelectList
              label="หมวดหมู่"
              fullWidth={true}
              emptyValue="กรุณาเลือก category"
              value={videoContent.category || 'none'}
              onChange={(e) =>
                handleBlockChange({
                  content: { ...videoContent, category: e.target.value }
                })
              }
              items={videos}
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={block.paginate}
                  onChange={handleSettingChange('paginate')}
                />
              }
              label={<Typography variant="body2">แสดงเป็นหน้า</Typography>}
            />
          </>
        );
      default:
        return null;
    }
  };
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paperWidthMd: dialog }}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box mr={1}>ประเภทข้อมูล: {type && type.name}</Box>
          <Tooltip title={type && type.description} placement="top">
            <Help fontSize="small" />
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <Box mb={1} alignItems="baseline">
          <FormControlLabel
            control={
              <Checkbox
                value="dark"
                defaultChecked={payload.block.dark}
                onChange={handleSettingChange('dark')}
              />
            }
            label={<Typography variant="body2">Dark BG</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="fullWidth"
                defaultChecked={payload.block.fullWidth}
                onChange={handleSettingChange('fullWidth')}
              />
            }
            label={<Typography variant="body2">Full Width</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="section"
                defaultChecked={payload.block.section}
                onChange={handleSettingChange('section')}
              />
            }
            label={<Typography variant="body2">Section</Typography>}
          />
        </Box>
        {renderEditArea()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          ยกเลิก
        </Button>
        <Button
          color="primary"
          onClick={() => onSave(payload.index, block)}
          // disabled={block.content === '' && !block.file} // comment out, some widgets do not have these props
        >
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SelectList = ({
  label,
  emptyValue,
  value,
  items,
  fullWidth,
  onChange
}) => {
  return (
    <TextField
      style={{ minWidth: 'auto' }}
      select
      label={label}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
    >
      <MenuItem value="none">
        <em>{emptyValue}</em>
      </MenuItem>
      {items.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

const BannerPicker = ({ block, onChange }) => {
  const [url, setUrl] = useState(block.content.image);
  const { imagePreview } = useStyles({ url });

  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      setUrl(target.result);
      onChange({ content: { ...block.content, image: target.result }, file });
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <Box className={imagePreview} />
      <Box display="flex">
        <Button variant="contained" color="primary" component="label" fullWidth>
          เลือกรูป
          <input
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            onChange={handleChange}
          />
        </Button>
      </Box>
      <Box mb={2} />
      <TextField
        label="Link"
        value={block.content.link || ''}
        onChange={(e) =>
          onChange({
            content: { ...block.content, link: e.target.value }
          })
        }
        fullWidth
      />
    </>
  );
};

const ContactList = ({ content, onChange, contacts, icons }) => {
  if (!contacts.find((item) => content.category === item.id)) {
    content.category = '';
    content.specialTag = false;
    delete content.specialTags;
  }
  return (
    <>
      <Box display="flex" justifyItems="flex-end">
        <SelectList
          label="ไอคอน"
          emptyValue="เลือก icon"
          value={content.icon || 'none'}
          onChange={(e) =>
            onChange({
              content: { ...content, icon: e.target.value }
            })
          }
          items={icons}
        />
        <Box mr={2} />
        <TextField
          fullWidth
          label="หัวข้อ"
          value={content.title || ''}
          onChange={(e) =>
            onChange({
              content: { ...content, title: e.target.value }
            })
          }
        />
        <Box mr={2} />
        <TextField
          fullWidth
          label="คำอธิบาย"
          value={content.description || ''}
          onChange={(e) =>
            onChange({
              content: { ...content, description: e.target.value }
            })
          }
        />
        <Box mr={2} />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={content.filter}
              onChange={(e) =>
                onChange({
                  content: { ...content, filter: e.target.checked }
                })
              }
            />
          }
          label="Filter"
        />
      </Box>
      <Box mb={2} />
      <SelectList
        items={contacts}
        label="หมวดหมู่"
        fullWidth={true}
        emptyValue="กรุณาเลือก category"
        value={content.category || 'none'}
        onChange={(e) =>
          onChange({
            content: {
              ...content,
              category: e.target.value,
              specialTag: e.target.value === 'none' ? false : content.specialTag
            }
          })
        }
      />
      <Box mb={2} />
      <FormControlLabel
        control={
          <Checkbox
            checked={content.specialTag || false}
            onChange={(e) =>
              onChange({
                content: { ...content, specialTag: e.target.checked }
              })
            }
            disabled={
              !content.category ||
              content.category === '' ||
              content.category === 'none'
            }
          />
        }
        label="คัดกรองโดยหมวดหมู่อื่น"
      />
      <Box mb={2} />
      {content.specialTag && (
        <SpecialTags
          specialTags={content.specialTags || {}}
          category={content.category}
          contacts={contacts}
          onChange={(specialTags) =>
            onChange({
              content: { ...content, specialTags }
            })
          }
        />
      )}
    </>
  );
};

const SpecialTags = ({ specialTags, category, contacts, onChange }) => {
  const [tags, setTags] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    const contact = contacts.find((c) => c.id === category);
    console.log(
      'setTags:',
      contact
        ? contact.items.reduce((result, item) => {
            return [
              ...result,
              ...item.tags.filter((tag) => !_.includes(result, tag))
            ];
          }, [])
        : []
    );
    setTags(
      contact
        ? contact.items.reduce((result, item) => {
            return [
              ...result,
              ...item.tags.filter((tag) => !_.includes(result, tag))
            ];
          }, [])
        : []
    );
  }, [category, contacts]);
  useEffect(() => {
    setData(specialTags);
  }, [specialTags]);
  return (
    <Grid container spacing={1}>
      {tags.map((tag, index) => (
        <Grid item key={index} md={4}>
          <SelectList
            items={contacts}
            label={tag}
            fullWidth={true}
            emptyValue="กรุณาเลือก category"
            value={data[tag] || 'none'}
            onChange={(e) =>
              onChange({ ...specialTags, [tag]: e.target.value })
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ContentDialog;
