import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Delete, Description, Edit } from '@material-ui/icons';
import GridForm from './GridForm';
import { postFileds } from '../configs/formSchema';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    backgroundColor: theme.palette.grey[200]
  },
  postAction: {
    minWidth: 88
  },
  postInfo: {
    flexGrow: 1,
    minWidth: 0
  },
  postItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1)
  }
}));

const PostList = ({ posts, onDelete, onSave }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [post, setPost] = useState({});
  const { dialogContent, postAction, postInfo, postItem } = useStyles();

  const handleOpenDialog = post => {
    setPost(post);
    setShowDialog(true);
  };

  const handleChange = update => {
    setPost(prev => ({ ...prev, ...update }));
  };

  return (
    <>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="flex-end" my={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog({})}
          >
            เพิ่มบทความใหม่
          </Button>
        </Box>
        {posts.map(post => (
          <Paper key={post.id} className={postItem}>
            <Box className={postInfo} display="flex">
              <Avatar>
                <Description />
              </Avatar>
              <Box mr={1} />
              <Box width={'75%'}>
                <Typography variant="body1" noWrap>
                  {post.title}
                </Typography>
                <Typography variant="caption" component="p" noWrap>
                  {post.excerpt || <span>&nbsp;</span>}
                </Typography>
              </Box>
            </Box>
            <Box className={postAction}>
              <IconButton onClick={() => handleOpenDialog(post)}>
                <Edit fontSize="small" />
              </IconButton>
              <IconButton onClick={() => onDelete(post)}>
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          </Paper>
        ))}
      </Container>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>แก้ไขบทความ</DialogTitle>
        <DialogContent className={dialogContent}>
          <GridForm fields={postFileds} values={post} onChange={handleChange} />
          <DialogActions>
            <Button color="primary" onClick={() => setShowDialog(false)}>
              ยกเลิก
            </Button>
            <Box mr={1} />
            <Button
              variant="contained"
              color="primary"
              disabled={!post.title}
              onClick={() => {
                onSave(post);
                setShowDialog(false);
              }}
            >
              บันทึก
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PostList;
