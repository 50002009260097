import React, { useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  imagePreview: {
    backgroundColor: theme.palette.common.black,
    backgroundImage: ({ url }) =>
      `url('${url ? url : 'https://via.placeholder.com/100'}')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginBottom: `${theme.spacing(1)}px`,
    height: '200px',
    width: '100%'
  }
}));

const BannerSwipeablePicker = ({ block, onChange }) => {
  const [items, setItems] = useState(block.content || []);

  const onAddBanner = () => {
    setItems([...items, {}]);
  };

  const handleChange = ({ content, index }) => {
    let contentItems = [...items];
    contentItems[index] = { ...content };
    setItems(contentItems);
    onChange({ content: [...contentItems] });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={2} />
      <Button
        variant="contained"
        color="primary"
        component="label"
        fullWidth
        onClick={onAddBanner}
      >
        เพิ่มรูป
      </Button>
      <Box mb={2} />
      {items.map((item, index) => (
        <BannerPicker
          key={index}
          index={index}
          item={item}
          onChange={handleChange}
        />
      ))}
    </Box>
  );
};

const BannerPicker = ({ item, index, onChange }) => {
  const [url, setUrl] = useState(item.image);
  const { imagePreview } = useStyles({ url });
  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      setUrl(target.result);
      onChange({
        content: { ...item, image: target.result, file },
        index
      });
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <Box className={imagePreview} />
      <Box display="flex">
        <Button variant="contained" color="primary" component="label" fullWidth>
          เลือกรูป
          <input
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            onChange={handleChange}
          />
        </Button>
      </Box>
      <Box mb={2} />
      <TextField
        label="Link"
        value={item.link || ''}
        onChange={(e) =>
          onChange({ content: { ...item, link: e.target.value }, index })
        }
        fullWidth
      />
    </>
  );
};

export default BannerSwipeablePicker;
