import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import GridForm from './GridForm';

const EditItemDialog = ({ fields, initialValues, open, onSubmit, onClose }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues, open]);

  const handleChange = update => {
    setValues({ ...values, ...update });
  };
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>แก้ไขข้อมูล</DialogTitle>
      <DialogContent>
        <GridForm fields={fields} values={values} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>ยกเลิก</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(values)}
        >
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditItemDialog;
