import _defineProperty from "C:\\Users\\Alice\\Documents\\Projects\\bkkhive\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectSpread from "C:\\Users\\Alice\\Documents\\Projects\\bkkhive\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectSpread2";

var _layout;

import { createMuiTheme } from '@material-ui/core';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
var muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Kanit, Roboto',
    h4: {
      color: '#1A2F5A'
    }
  },
  palette: {
    primary: {
      main: red[500]
    }
  }
});

var theme = _objectSpread(_objectSpread({}, muiTheme), {}, {
  layout: (_layout = {}, _defineProperty(_layout, muiTheme.breakpoints.down('md'), {
    padding: "0px ".concat(muiTheme.spacing(2), "px")
  }), _defineProperty(_layout, muiTheme.breakpoints.up('lg'), {
    padding: "0px calc((100% - ".concat(muiTheme.breakpoints.values.lg, "px) / 2)")
  }), _layout),
  grow: {
    flexGrow: 1
  },
  responsiveImage: {
    display: 'flex',
    width: '100%'
  },
  section: {
    paddingBottom: '16px'
  },
  truncatedText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

export default responsiveFontSizes(theme);