import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import Browse from './Browse';

const ImportDialog = ({ open, onImport, onClose, file, setFile }) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>กรุณาเลือกไฟล์</DialogTitle>
      <DialogContent>
        <Browse file={file} setFile={setFile} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>ยกเลิก</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onImport}
          disabled={file === null}
        >
          นำเข้า
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
