import React, { useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Delimiter from '@editorjs/delimiter';
import Header from '@editorjs/header';
import Image from '@editorjs/image';
import List from '@editorjs/list';

const holderId = 'editorjs-holder';

const TextEditor = ({ data, uploadByFile, onChange, ...props }) => {
  const editor = useRef();
  const handleChange = async () => {
    const data = await editor.current.save();
    onChange(data);
  };

  useEffect(() => {
    editor.current = new EditorJS({
      holderId,
      autofocus: false,
      placeholder: 'เริ่มเขียนข้อความที่นี่',
      tools: {
        header: Header,
        image: {
          class: Image,
          config: {
            uploader: {
              uploadByFile
            }
          }
        },
        list: List,
        delimiter: Delimiter,
      },
      data,
      onChange: handleChange,
      onReady: () => {}
    });
    return () => {
      editor.current.destroy();
      editor.current = null;
    };
  }, []);

  return React.createElement('div', {
    id: holderId,
    ...props
  });
};

export default TextEditor;
