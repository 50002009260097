import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Typography
} from '@material-ui/core';
import { ExpandMore, Mail, CloudDownload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import ItemList from './ItemList';
import EditItemDialog from './EditItemDialog';
import ConfirmDialog from './ConfirmDialog';
import ImportDialog from './ImportDialog';

const useStyles = makeStyles(theme => ({
  expansionDetails: {
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    flexDirection: 'column'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const CategoryList = ({
  formFields,
  initialData,
  onSave,
  onImportFile,
  onDelete,
  onExportFile
}) => {
  const [categories, setCategories] = useState(initialData);
  const [editingItem, setEditingItem] = useState();
  const [editingCategory, setEditingCategory] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [confirmImportOpen, setConfirmImportOpen] = useState(false);
  const [importFile, setImportFile] = useState(null);

  useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      setCategories(initialData);
    }
    return () => {
      didCancel = true;
    };
  }, [initialData]);

  const addNewCategory = () => {
    setCategories([...categories, { items: [] }]);
  };
  const handleChange = index => name => data => {
    let array = [...categories];
    array[index][name] = data;
    setCategories(array);
  };
  const handleItemChanged = item => {
    let items = [...categories[editingCategory].items];
    items[editingItem] = item;
    handleChange(editingCategory)('items')(items);
  };
  const openEditDialog = (categoryIndex, itemIndex) => {
    setEditingCategory(categoryIndex);
    setEditingItem(itemIndex);
    setShowEdit(true);
  };
  const handleImportFile = async () => {
    try {
      await onImportFile(importFile);
      setCategories(initialData);
      setImportOpen(false);
      setConfirmImportOpen(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Container maxWidth={'md'}>
      <Box display="flex" my={1} justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={addNewCategory}>
          เพิ่มหมวดหมู่
        </Button>
        <Box mr={1} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setImportOpen(true)}
        >
          นำเข้าข้อมูล
        </Button>
        <Box mr={1} />
        <Button variant="contained" color="primary" onClick={onExportFile}>
          <CloudDownload />
        </Button>
      </Box>
      {categories.map((category, index) => (
        <CategoryPanel
          key={index}
          data={category}
          index={index}
          onEditItem={openEditDialog}
          onChange={handleChange}
          onSave={(data, index) => onSave(data, index)}
          onDelete={(data, index) => onDelete(data, index)}
        />
      ))}
      {editingCategory !== undefined && editingItem !== undefined && (
        <EditItemDialog
          fields={formFields}
          initialValues={
            categories[editingCategory]
              ? categories[editingCategory].items[editingItem]
              : {}
          }
          open={showEdit}
          onClose={() => setShowEdit(false)}
          onSubmit={values => {
            handleItemChanged(values);
            setShowEdit(false);
          }}
        />
      )}
      <ImportDialog
        open={importOpen}
        onClose={() => setImportOpen(false)}
        onImport={() => setConfirmImportOpen(true)}
        file={importFile}
        setFile={setImportFile}
      />
      <ConfirmDialog
        title="Confirm"
        content="ยืนยันที่จะ import?"
        open={confirmImportOpen}
        onOk={handleImportFile}
        onClose={() => setConfirmImportOpen(false)}
      />
    </Container>
  );
};

const CategoryPanel = ({
  data,
  index,
  onChange,
  onEditItem,
  onSave,
  onDelete
}) => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [confirmData, setConfirmData] = useState({
    content: '',
    open: false,
    onOk: () => {},
    onClose: () => {}
  });
  const { expansionDetails, progress } = useStyles();
  const handleCreateItem = () => {
    onChange(index)('items')(data.items ? [...data.items, {}] : [{}]);
  };
  const handleChange = name => event => {
    onChange(index)(name)(event.target.value);
  };
  const handleOpenConfirmDeleteItem = itemIndex => {
    setConfirmData({
      open: true,
      content: 'Confirm to Delete?',
      onOk: () => handleDeleteItem(itemIndex),
      onClose: () => setConfirmData({ open: false })
    });
  };
  const handleDeleteItem = itemIndex => {
    setConfirmData({ open: false })
    const items = data.items.filter((_, index) => index !== itemIndex);
    onChange(index)('items')(items);
  };
  const handleSave = async () => {
    setLoading(true);
    await onSave(data, index);
    setLoading(false);
  };
  const handleOpenConfirmDeleteCategory = () => {
    setConfirmData({
      open: true,
      content: 'Confirm to Delete?',
      onOk: () => handleDeleteCategory(),
      onClose: () => setConfirmData({ open: false })
    });
  };
  const handleDeleteCategory = async () => {
    setConfirmData({ open: false })
    setLoading(true);
    await onDelete(data, index);
    setExpanded(false);
    setLoading(false);
  };

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={(e, isExpanded) => setExpanded(isExpanded)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Typography>
          หมวดหมู่: {data.name ? data.name : 'New Category'}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={expansionDetails}>
        <Box width="100%">
          <ItemList
            icon={<Mail fontSize="small" />}
            list={data.items}
            onCreate={handleCreateItem}
            onEdit={itemIndex => onEditItem(index, itemIndex)}
            onDelete={handleOpenConfirmDeleteItem}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <TextField
            label="Rename catagory"
            margin="normal"
            value={data.name ? data.name : ''}
            onChange={handleChange('name')}
          />
          <Box display="flex">
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleOpenConfirmDeleteCategory}
            >
              ลบ
              {loading && <CircularProgress size={24} className={progress} />}
            </Button>
            <Box mr={1} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              บันทึก
              {loading && <CircularProgress size={24} className={progress} />}
            </Button>
          </Box>
        </Box>
        <ConfirmDialog
          title="Confirm"
          content={confirmData.content}
          open={confirmData.open}
          onOk={confirmData.onOk}
          onClose={confirmData.onClose}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CategoryList;
