import _ from 'lodash';
import districtJson from '../data/district_th.json';
import contactTagsJson from '../data/contact_tags.json';
var districtsArr = [];

var districts = function districts() {
  if (districtsArr.length === 0) {
    districtsArr = districtJson.th.amphoes.map(function (district) {
      return {
        text: district.name,
        value: district.name
      };
    }).sort(function (a, b) {
      if (a.text < b.text) {
        return -1;
      }

      if (a.text > b.text) {
        return 1;
      }

      return 0;
    });
  }

  return districtsArr;
};

var contactTagsByPage = function contactTagsByPage(page) {
  return contactTagsJson.tags.filter(function (tag) {
    return _.includes(tag.pages, page);
  });
};

export default {
  districts: districts,
  contactTagsByPage: contactTagsByPage
};