import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StorageService from 'common/services/storage';
import TextEditor from './TextEditor';

const uploadByFile = async file => {
  const url = await StorageService.upload('/contents', file);
  return {
    success: 1,
    file: { url }
  };
};

const useStyles = makeStyles(theme => ({
  imagePreview: {
    backgroundColor: theme.palette.common.black,
    backgroundImage: ({ url }) =>
      `url('${url ? url : 'https://via.placeholder.com/100'}')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginBottom: `${theme.spacing(1)}px`,
    height: '200px',
    width: '100%'
  },
  inputLabel: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  root: {
    padding: theme.spacing(1)
  }
}));

const GridForm = ({ fields, values, onChange }) => {
  const { inputLabel, root } = useStyles();

  const renderField = field => {
    const { file, label, multiline, name, options, type } = field;
    switch (type) {
      case 'string':
        return (
          <TextField
            fullWidth
            label={label}
            margin="normal"
            multiline={multiline}
            value={values[name] || ''}
            onChange={e => onChange({ [name]: e.target.value })}
          />
        );
      case 'select':
        return (
          <TextField
            select
            fullWidth
            label={label}
            margin="normal"
            value={values[name] || 'none'}
            onChange={e => onChange({ [name]: e.target.value })}
          >
            <MenuItem value={'none'}>
              <em>กรุณาเลือก</em>
            </MenuItem>
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      case 'image':
        return (
          <>
            <Typography className={inputLabel} variant="caption">
              {label}
            </Typography>
            <Box mb={1} />
            <ImagePicker
              imageUrl={values[name]}
              nameKey={name}
              fileKey={file}
              onChange={update => onChange(update)}
            />
          </>
        );
      case 'file':
        return (
          <>
            <Typography className={inputLabel} variant="caption">
              {label}
            </Typography>
            <Box mb={1} />
            <FilePicker
              fileUrl={values[name]}
              nameKey={name}
              fileKey={file}
              fileData={values[file]}
              onChange={update => {
                onChange(update);
              }}
            />
          </>
        );
      case 'text':
        return (
          <>
            <Typography className={inputLabel} variant="caption">
              {label}
            </Typography>
            <Box mb={1} />
            <TextEditor
              data={values[name]}
              onChange={data => onChange({ [name]: data })}
              uploadByFile={uploadByFile}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Grid container className={root} spacing={2}>
      {fields.map(field => (
        <Grid item key={field.name} xs={12} md={field.fullWidth ? 12 : 6}>
          {renderField(field)}
        </Grid>
      ))}
    </Grid>
  );
};

const ImagePicker = ({ imageUrl, nameKey, fileKey, onChange }) => {
  const [url, setUrl] = useState(imageUrl);
  const { imagePreview } = useStyles({ url });

  const handleChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      setUrl(target.result);
      onChange({ [nameKey]: target.result, [fileKey]: { file, key: nameKey } });
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Box className={imagePreview} />
      <Box display="flex">
        <Button variant="contained" color="primary" component="label" fullWidth>
          เลือกรูป
          <input
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            onChange={handleChange}
          />
        </Button>
      </Box>
    </>
  );
};

const FilePicker = ({ fileUrl, nameKey, fileKey, onChange, fileData }) => {
  const [url, setUrl] = useState(fileUrl);
  const [fileName, setFileName] = useState(
    fileData ? fileData.name : 'ยังไม่ได้เลือกไฟล์'
  );
  const handleChange = e => {
    const selected = e.target.files[0];
    setFileName(selected.name);
    setUrl(null);
    onChange({
      [nameKey]: null,
      [fileKey]: { file: selected, key: nameKey, name: selected.name }
    });
  };
  return (
    <Box display="flex" alignItems="center">
      <Button variant="contained" color="primary" component="label">
        เลือกไฟล์
        <input
          accept="*"
          type="file"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
      </Button>
      <Box mr={1} />
      <Box width="50%">
        {url ? (
          <Link component={Button} href={url} target="_blank" underline="none">
            ดูไฟล์
          </Link>
        ) : (
          <Typography variant="body2" noWrap>
            {fileName}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default GridForm;
