export var menuMap = [{
  text: 'หน้าหลัก',
  link: '/home-general',
  menu: false
}, {
  text: 'เกี่ยวกับเรา',
  link: '/about-us',
  menu: true
}, {
  text: 'เอชไอวีและเอดส์',
  link: '/hiv',
  menu: true
}, {
  text: 'โรคติดต่อทางเพศสัมพันธ์',
  link: '/disease',
  menu: true
}, {
  text: 'วัณโรค ',
  link: '/tb',
  menu: true
}, {
  text: 'บริการตรวจ/รักษา',
  link: '/service',
  menu: true
}, {
  text: 'ถุงยางอนามัยและสารหล่อลื่น',
  link: '/condom',
  menu: true
}, {
  text: 'PrEP/PEP',
  link: '/preppep',
  menu: true
}, {
  text: 'S&D',
  link: '/s-and-d',
  menu: true
}, {
  text: 'สุขภาพคนข้ามเพศ',
  link: '/transgender',
  menu: true
}, {
  text: 'สุขภาพจิต',
  link: '/mental-health',
  menu: true
}, {
  text: 'ตั้งครรภ์ไม่พร้อม',
  link: '/pregnant',
  menu: true
}, {
  text: 'หน่วยบริการ',
  link: '/search',
  menu: true
}, {
  text: 'ประชาสัมพันธ์',
  link: '/public-relation',
  menu: true
}, {
  text: 'หน้าหลักวัยรุ่น',
  link: '/home-teenage',
  menu: false
}, {
  text: 'หน้าหลักคนข้ามเพศ',
  link: '/home-tg',
  menu: false
}, {
  text: 'หน้าหลักชายรักชาย',
  link: '/home-msm',
  menu: false
}];
export var homeMenus = ['home-general', 'home-teenage', 'home-tg', 'home-msm'];
export var headerMenus = [{
  text: '',
  submenus: [{
    text: 'เกี่ยวกับเรา',
    link: '/about-us'
  }]
}, {
  text: 'โรคติดต่อทางเพศสัมพันธ์และเอดส์',
  submenus: [{
    text: 'เอชไอวีและเอดส์',
    link: '/hiv'
  }, {
    text: 'โรคติดต่อทางเพศสัมพันธ์',
    link: '/disease'
  }, {
    text: 'ตั้งครรภ์ไม่พร้อม',
    link: '/pregnant'
  }]
}, {
  text: '',
  submenus: [{
    text: 'วัณโรค ',
    link: '/tb'
  }]
}, {
  text: 'หน่วยบริการ',
  submenus: [{
    text: 'บริการตรวจ/รักษา',
    link: '/service'
  }, {
    text: 'ค้นหาหน่วยบริการ',
    link: '/search'
  }]
}, {
  text: 'การป้องกัน',
  submenus: [{
    text: 'ถุงยางอนามัยและสารหล่อลื่น',
    link: '/condom'
  }, {
    text: 'PrEP/PEP',
    link: '/preppep'
  }]
}, {
  text: '',
  submenus: [{
    text: 'S&D',
    link: '/s-and-d'
  }]
}, {
  text: '',
  submenus: [{
    text: 'สุขภาพคนข้ามเพศ',
    link: '/transgender'
  }]
}, {
  text: '',
  submenus: [{
    text: 'สุขภาพจิต',
    link: '/mental-health'
  }]
}, {
  text: '',
  submenus: [{
    text: 'มินิเกม',
    link: '/game-center'
  }]
}, {
  text: '',
  submenus: [{
    text: 'ประชาสัมพันธ์',
    link: '/public-relation'
  }]
}];