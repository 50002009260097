import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import MaterialTable from 'material-table';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { menuMap } from 'common/menus';
import { fetchView, fetchSession } from 'common/services/stat';
import CloudService from 'common/services/cloud';
import StorageService from 'common/services/storage';
import { exportData as exportSubscriptionData } from 'common/services/subscription';

const columns = [
  { title: 'เพจ', field: 'page' },
  { title: 'การเข้าชม (ครั้ง)', field: 'enter' }
];
const popupKey = 'popup';

const mapWithMenu = (results) => (menu) => {
  const matched = results.find((result) => result.page === menu.link);
  return matched ? { page: menu.text, enter: parseInt(matched.views) } : null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  filterSelector: {
    minWidth: theme.spacing(12)
  },
  imagePreview: {
    backgroundColor: theme.palette.common.black,
    backgroundImage: ({ url }) =>
      `url('${url ? url : 'https://via.placeholder.com/100'}')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginBottom: `${theme.spacing(1)}px`,
    height: '200px',
    width: '100%'
  }
}));

const Dashboard = () => {
  const [stat, setStat] = useState([]);
  const [filter, setFilter] = useState('alltime');
  const [date, setDate] = useState(moment());
  const [popup, setPopup] = useState({});
  const [loadingPopup, setLoadingPopup] = useState(true);
  const [snackbar, setSnackbar] = useState('');
  const { root, filterSelector } = useStyles();

  const exportFeedback = async () => {
    try {
      await CloudService.exportFeedbackData();
      console.log('export success');
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const exportSubscriptions = async () => {
    try {
      await exportSubscriptionData();
      console.log('export success');
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    fetchView(filter).then((views) => {
      fetchSession(filter).then((sessions) => {
        let mapped = menuMap
          .map(mapWithMenu(views))
          .filter((menu) => menu !== null);
        mapped = [
          { page: 'เว็บไซต์', enter: parseInt(sessions.session) },
          ...mapped
        ];
        setStat(mapped);
      });
    });
    CloudService.fetch('settings', 'popup').then((item) => {
      setPopup({ ...item, id: popupKey });
      setLoadingPopup(false);
    });
  }, [filter]);

  return (
    <>
      <Container className={root} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box component={Paper} minHeight="480px" p={2} mb={1}>
              <MaterialTable
                components={{ Container: Box }}
                title="สถิติการเข้าใช้งาน"
                columns={columns}
                data={stat}
                options={{
                  exportAllData: true,
                  exportButton: true,
                  exportFileName: 'report',
                  search: false
                }}
                localization={{
                  body: { emptyDataSourceMessage: 'กำลังโหลดข้อมูล ...' },
                  toolbar: { exportName: 'ส่งออกข้อมูล' }
                }}
              />
              <Box display="flex" alignItems="baseline" px={2}>
                <TextField
                  className={filterSelector}
                  select
                  label="เลือกช่วงเวลา"
                  margin="normal"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <MenuItem key={'alltime'} value={'alltime'}>
                    ตลอดเวลาที่ผ่านมา
                  </MenuItem>
                  <MenuItem key={'today'} value={'today'}>
                    วันนี้
                  </MenuItem>
                  <MenuItem key={'yesterday'} value={'yesterday'}>
                    เมื่อวาน
                  </MenuItem>
                  <MenuItem key={'thismonth'} value={'thismonth'}>
                    เดือนนี้
                  </MenuItem>
                  <MenuItem key={'lastmonth'} value={'lastmonth'}>
                    เดือนก่อน
                  </MenuItem>
                  <MenuItem key={'thisweek'} value={'thisweek'}>
                    สัปดาห์นี้
                  </MenuItem>
                  <MenuItem key={'lastweek'} value={'lastweek'}>
                    สัปดาห์ก่อน
                  </MenuItem>
                  {/* <MenuItem key={'date'} value={'date'}>
                  ระบุวันที่
                </MenuItem> */}
                </TextField>
                <Box mr={2} />
                {filter === 'date' && (
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="เลือกวัน"
                      value={date}
                      onChange={(val) => setDate(val)}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Box>
            </Box>
            <Box component={Paper} p={4} mb={1}>
              <Typography variant="h6">Email ของผู้สมัครรับข่าวสาร</Typography>
              <Box mb={2} />
              <Button
                variant="contained"
                color="primary"
                onClick={exportSubscriptions}
              >
                ส่งออกเป็นไฟล์ Excel
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box component={Paper} p={4} mb={1}>
              <Typography variant="h6">วิธีการใช้งาน</Typography>
              <Box mb={2} />
              <Typography>
                คู่มือการใช้งานดาวน์โหลดได้{' '}
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/docs%2F%E0%B8%84%E0%B8%B9%E0%B9%88%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%87%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%99%20BKK%20Hive.pdf?alt=media&token=9f768ac5-6a9f-403d-8738-86bbc5090b33"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ที่นี่
                </a>
              </Typography>
            </Box>
            <Box component={Paper} p={4} mb={1}>
              <Typography variant="h6">ข้อมูลของผู้ตอบแบบสอบถาม</Typography>
              <Box mb={2} />
              <Button
                variant="contained"
                color="primary"
                onClick={exportFeedback}
              >
                ส่งออกเป็นไฟล์ Excel
              </Button>
            </Box>
            {!loadingPopup && (
              <Popup
                popup={popup}
                setPopup={setPopup}
                setSnackbar={setSnackbar}
              />
            )}
          </Grid>
          <Grid item xs={12} md={7}></Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snackbar !== ''}
        autoHideDuration={3000}
        message={snackbar}
        onClose={() => setSnackbar('')}
      />
    </>
  );
};

const Popup = ({ popup, setPopup, setSnackbar }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(popup.image || '');

  console.log('popup: ', popup);
  const { imagePreview } = useStyles({
    url: image === '' ? popup.image || '' : image
  });
  const handleCheckboxChange = (name) => (e) => {
    setPopup({ ...popup, [name]: e.target.checked });
  };
  const handleInputFieldChange = (name) => (e) => {
    setPopup({ ...popup, [name]: e.target.value });
  };
  const handleSelectFile = (name) => (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      setImage(target.result);
      setPopup({ ...popup, [`${name}File`]: file });
    };
    reader.readAsDataURL(file);
  };
  const save = async () => {
    setLoading(true);
    let popupData = { ...popup };
    if (popupData.imageFile) {
      const res = await StorageService.upload(
        `/${popupKey}`,
        popupData.imageFile
      );
      popupData.image = res;
      delete popupData.imageFile;
    }
    console.log(`popupData: `, popupData);
    await CloudService.save('settings', popupData);
    setLoading(false);
    setSnackbar('บันทึกข้อมูลสำเร็จ');
  };
  return (
    <>
      <Box component={Paper} p={4}>
        <Typography variant="h6">Pop-up</Typography>
        <Box mb={2} />
        <Box>
          <Box className={imagePreview} />
          <Button
            variant="contained"
            color="primary"
            component="label"
            fullWidth
            disabled={loading}
          >
            เลือกรูป
            <input
              accept="image/*"
              type="file"
              style={{ display: 'none' }}
              onChange={handleSelectFile('image')}
            />
          </Button>
        </Box>
        <Box mb={2} />
        <Box>
          <TextField
            label="Link"
            value={popup.link || ''}
            onChange={handleInputFieldChange('link')}
            fullWidth
          />
        </Box>
        <Box display="flex" my={1} justifyContent="space-between">
          <FormControlLabel
            control={
              <Checkbox
                value="active"
                checked={popup.active}
                onChange={handleCheckboxChange('active')}
                disabled={loading}
              />
            }
            label={<Typography variant="body2">ใช้งาน</Typography>}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={save}
            disabled={loading}
          >
            บันทึกข้อมูล
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
