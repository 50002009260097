import React from 'react';
import {
  Avatar,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Add, Edit, Delete } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: `-${theme.spacing(2)}px`,
    right: `${theme.spacing(2)}px`
  },
  listItem: {
    backgroundColor: '#FFFF',
    borderBottom: `${theme.palette.grey[200]} solid 1px`
  },
  root: {
    backgroundColor: theme.palette.common.white,
    border: `${theme.palette.grey[500]} solid 1px`,
    paddingBottom: theme.spacing(4)
  }
}));

const ItemList = ({ icon, list, onCreate, onEdit, onDelete }) => {
  const { root, fab, listItem } = useStyles();
  return (
    <List classes={{ root }}>
      {list.map((item, index) => (
        <ListItem key={index} className={listItem}>
          <ListItemAvatar>
            <Avatar>{icon}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.title || '[untitled]'} />
          <ListItemSecondaryAction>
            <IconButton onClick={() => onEdit(index)}>
              <Edit fontSize="small" />
            </IconButton>
            <IconButton onClick={() => onDelete(index)}>
              <Delete fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      <Fab
        className={fab}
        size="small"
        color="secondary"
        onClick={() => onCreate()}
      >
        <Add fontSize="small" />
      </Fab>
    </List>
  );
};

export default ItemList;
