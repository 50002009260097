import React, { useState, useEffect } from 'react';
import { Box, Snackbar } from '@material-ui/core';
import CloudService from 'common/services/cloud';
import StorageService from 'common/services/storage';

import CategoryList from '../components/CategoryList';

const ManageItem = ({ history, collection, formFields }) => {
  const [initialData, setInitialData] = useState();
  const [snackbar, setSnackbar] = useState('');

  useEffect(() => {
    setInitialData();
    CloudService.fetchAll(collection).then(data => setInitialData(data));
  }, [history.location.pathname, collection]);

  const handleSave = async (data, index) => {
    try {
      console.log('handleSave');
      let promises = [];
      data.items.forEach(item => {
        let itemPromises = [];
        if (item.imageFile) {
          itemPromises = [
            StorageService.upload(`${collection}/images`, item.imageFile.file)
          ];
          // promises = [
          //   ...promises,
          //   StorageService.upload(`${collection}/logo`, item.imageFile.file)
          // ];
        } else {
          itemPromises = [...itemPromises, null];
        }
        if (item.file) {
          itemPromises = [
            ...itemPromises,
            StorageService.upload(`${collection}/files`, item.file.file)
          ];
        } else {
          itemPromises = [...itemPromises, null];
        }
        promises = [...promises, Promise.all(itemPromises)];
        if (item.tag) {
          item.tags = item.tag.split(',');
        }
      });
      const resArr = await Promise.all(promises);
      resArr.forEach((res, index) => {
        if (res) {
          let item = data.items[index];
          res.forEach((url, index) => {
            if (url) {
              if (index === 0) {
                item[item.imageFile.key] = url;

                delete item.imageFile;
              } else {
                item[item.file.key] = url;

                delete item.file;
              }
            }
          });
        }
      });
      const id = await CloudService.save(collection, data);
      let list = [...initialData];
      list[index] = { ...data, id };
      setInitialData(list);
      setSnackbar('บันทึกข้อมูลสำเร็จ');
    } catch (error) {
      console.error(error);
    }
  };

  const handleImportFile = async file => {
    try {
      await CloudService.importData(collection, file);
      const data = await CloudService.fetchAll(collection);
      setInitialData(data);
      setSnackbar('นำเข้าข้อมูลสำเร็จ');
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleExportFile = async () => {
    try {
      await CloudService.exportData(collection);
      console.log('export success');
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleDelete = async (data, index) => {
    if (data.id) {
      try {
        await CloudService.deleteData(collection, data);
        setSnackbar('ลบข้อมูลแล้ว');
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    let list = [...initialData];
    list.splice(index, 1);
    setInitialData(list);
  };

  if (!initialData) {
    return (
      <Box display="flex" justifyContent="center" p={4}>
        Loading ...
      </Box>
    );
  }

  return (
    <>
      <CategoryList
        formFields={formFields}
        initialData={initialData}
        onSave={handleSave}
        onDelete={handleDelete}
        onImportFile={handleImportFile}
        onExportFile={handleExportFile}
      />
      <Snackbar
        open={snackbar !== ''}
        autoHideDuration={3000}
        message={snackbar}
        onClose={() => setSnackbar('')}
      />
    </>
  );
};

export default ManageItem;
