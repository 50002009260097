import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { Lock } from '@material-ui/icons';

const LoginDialog = ({ loading, onSubmit }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = () => {
    onSubmit({ username, password });
  };
  return (
    <Container maxWidth="sm">
      <Paper>
        <Box
          bgcolor="primary.main"
          p={2}
          color="common.white"
          display="flex"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center">
            <Lock />
            <Box mx={0.5} />
            <Typography variant="h6">เข้าสู่ะบบ</Typography>
          </Box>
        </Box>
        <Box
          px={4}
          py={2}
          component={'form'}
          onSubmit={e => e.preventDefault()}
        >
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            label="Username"
            value={username}
            disabled={loading}
            onChange={e => setUsername(e.target.value)}
          />
          <Box py={1} />
          <TextField
            fullWidth
            variant="outlined"
            label="Password"
            type="password"
            value={password}
            disabled={loading}
            onChange={e => setPassword(e.target.value)}
          />
          <Box py={1} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            ส่งข้อมูล
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginDialog;
