import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import arrayMove from 'array-move';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0.5, 0)
  },
  paper: {
    width: `${theme.spacing(30)}px`,
    height: `${theme.spacing(40)}px`,
    overflow: 'auto'
  },
  root: {
    margin: 'auto'
  },
  sortableHelper: {
    zIndex: 1301 // dialog has z-index at 1300
  },
  staticItem: {
    cursor: 'pointer'
  }
}));

const not = (a, b) => {
  return a.filter(value => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
  return a.filter(value => b.indexOf(value) !== -1);
};

const TransferList = ({ initialLeft, initialRight, onChange }) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(initialLeft);
  const [right, setRight] = useState(initialRight);
  const [hint, setHint] = useState('');
  const { button, paper, root, sortableHelper, staticItem } = useStyles();

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    const newLeft = not(left, leftChecked);
    const newRight = right.concat(leftChecked);
    setRight(newRight);
    setLeft(newLeft);
    onChange(newLeft, newRight);
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    const newRight = not(right, rightChecked);
    setLeft(newLeft);
    setRight(newRight);
    onChange(newLeft, newRight);
    setChecked(not(checked, rightChecked));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newRight = arrayMove(right, oldIndex, newIndex);
    setRight(newRight);
    onChange(left, newRight);
  };

  const StaticList = ({ items, setHint }) => (
    <List dense component="div" role="list">
      {items.map((item, index) => (
        <StaticItem key={index} item={item} setHint={setHint} />
      ))}
    </List>
  );

  const StaticItem = ({ item, setHint }) => (
    <ListItem
      className={staticItem}
      role="listitem"
      onClick={() => setHint(item.title)}
    >
      <ListItemIcon style={{ minWidth: 0 }}>
        <Checkbox
          checked={checked.indexOf(item) !== -1}
          tabIndex={-1}
          disableRipple
          onChange={handleToggle(item)}
        />
      </ListItemIcon>
      <ListItemText disableTypography>
        <Typography variant="body2" noWrap style={{ fontSize: 12 }}>
          {item.title}
        </Typography>
      </ListItemText>
    </ListItem>
  );

  const SortableList = SortableContainer(({ items, setHint }) => (
    <List dense component="div" role="list">
      {items.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          item={item}
          setHint={setHint}
        />
      ))}
    </List>
  ));

  const SortableItem = SortableElement(({ item, setHint }) => (
    <StaticItem item={item} setHint={setHint} />
  ));

  return (
    <>
      <Grid
        container
        className={root}
        spacing={2}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Box p={1}>ไม่ถูกใช้งาน</Box>
          <Paper className={paper}>
            <StaticList items={left} setHint={setHint} />
          </Paper>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Box p={1}>ถูกใช้งาน (กดค้างเพื่อจัดลำดับ)</Box>
          <Paper className={paper}>
            <SortableList
              pressDelay={500}
              items={right}
              setHint={setHint}
              helperClass={sortableHelper}
              onSortEnd={onSortEnd}
            />
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={hint !== ''}
        message={hint}
        autoHideDuration={3000}
        onClose={() => setHint('')}
      />
    </>
  );
};

export default TransferList;
