import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';

const Browse = ({ file, setFile, acceptOnlyImg, imgUrl }) => {
  const [tempImg, setTempImg] = useState(null);
  useEffect(() => {
    if (acceptOnlyImg) {
      setTempImg(imgUrl);
    }
  }, [acceptOnlyImg, imgUrl]);
  const handleChangeFile = e => {
    if (acceptOnlyImg) {
      readUrlFromFile(e.target.files[0]);
    }
    setFile(e.target.files[0]);
  };

  const readUrlFromFile = async file => {
    let reader = new FileReader();
    reader.onload = e => {
      setTempImg(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  return (
    <Box display="flex" alignItems="baseline">
      <Button variant="contained" component="label">
        Browse
        <input
          accept={acceptOnlyImg ? 'image/*' : '*'}
          type="file"
          style={{ display: 'none' }}
          onChange={handleChangeFile}
        />
      </Button>
      <Box mr={2} />
      <Typography>{file && file.name}</Typography>
      {tempImg && <img src={tempImg} alt="" />}
    </Box>
  );
};

export default Browse;
