import React, { useState } from 'react';
import { Box, Snackbar } from '@material-ui/core';
import { auth } from 'common/firebase';
import LoginDialog from '../components/LoginDialog';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async ({ username, password }) => {
    try {
      setLoading(true);
      await auth.signInWithEmailAndPassword(username, password);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.message);
      setShowSnackbar(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <LoginDialog loading={loading} onSubmit={handleSubmit} />
      </Box>
      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={3000}
        message={errorMessage}
      />
    </>
  );
};

export default Login;
