import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  Description,
  ExitToApp,
  Dashboard,
  Menu,
  Settings
} from '@material-ui/icons';
import { auth } from 'common/firebase';
import { menuMap } from 'common/menus';

const manageMenus = [
  { text: 'ที่ติดต่อ', link: '/manage-contact' },
  { text: 'ลิงค์ภายนอก', link: '/manage-link' },
  { text: 'นัดตรวจออนไลน์', link: '/manage-appointment' },
  { text: 'บทความ', link: '/manage-post' },
  { text: 'ไฟล์ประกอบ', link: '/manage-file' },
  { text: 'วิดีโอ', link: '/manage-video' }
];

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth
    }
  },
  drawer: {
    width: drawerWidth
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden'
  },
  icon: {
    marginRight: theme.spacing(0.5)
  },
  link: {
    color: theme.palette.common.black,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  root: {},
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const SideBar = withRouter(({ location, open, onClose }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { drawer, drawerPaper, icon, link } = useStyles();
  const { pathname } = location;
  return (
    <Drawer
      className={drawer}
      classes={{
        paper: drawerPaper
      }}
      variant={mdUp ? 'permanent' : 'temporary'}
      open={open}
      onClose={onClose}
    >
      <AppBar style={{ width: drawerWidth }} position="static">
        <Toolbar>
          <Description className={icon} />
          แก้ไขหน้า
        </Toolbar>
      </AppBar>
      <List>
        {menuMap
          .map((menu) => menu)
          .map((menu, index) => (
            <Link
              className={link}
              style={{
                color: pathname === menu.link && theme.palette.primary.main
              }}
              key={index}
              component={RouterLink}
              to={menu.link}
              onClick={onClose}
            >
              <ListItem button>
                <ListItemText>
                  <Typography noWrap>{menu.text}</Typography>
                </ListItemText>
              </ListItem>
            </Link>
          ))}
      </List>
      <AppBar style={{ width: drawerWidth }} position="static">
        <Toolbar>
          <Settings className={icon} />
          จัดการข้อมูล
        </Toolbar>
      </AppBar>
      <List>
        {manageMenus.map((menu, index) => (
          <Link
            className={link}
            style={{
              color: pathname === menu.link && theme.palette.primary.main
            }}
            key={index}
            component={RouterLink}
            to={menu.link}
            onClick={onClose}
          >
            <ListItem button>
              <ListItemText>
                <Typography noWrap>{menu.text}</Typography>
              </ListItemText>
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
});

const Scaffold = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const { appBar, content, icon, link, root, toolbar } = useStyles();
  return (
    <div className={root}>
      <AppBar className={appBar} position="static" color="default">
        <Toolbar className={toolbar}>
          <Box>
            <Hidden mdUp>
              <IconButton onClick={() => setShowSideBar(true)}>
                <Menu style={{ color: 'white' }} />
              </IconButton>
            </Hidden>
            <Link className={link} component={RouterLink} to="/">
              <Button style={{ color: 'white' }}>
                <Dashboard className={icon} /> แดชบอร์ด
              </Button>
            </Link>
          </Box>
          <Button color="inherit" onClick={() => auth.signOut()}>
            <ExitToApp className={icon} />
            ออกจากระบบ
          </Button>
        </Toolbar>
      </AppBar>
      <SideBar open={showSideBar} onClose={() => setShowSideBar(false)} />
      <div className={content}>{children}</div>
    </div>
  );
};

export default Scaffold;
