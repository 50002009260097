import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { yellow } from '@material-ui/core/colors';
import { Error } from '@material-ui/icons';
import WidgetList from '../components/WidgetList';
import ContentDialog from '../components/ContentDialog';
import ConfirmDialog from '../components/ConfirmDialog';

import CloudService from 'common/services/cloud';
import PageService from 'common/services/page';
import StorageService from 'common/services/storage';

const useStyles = makeStyles({
  paperRoot: {
    backgroundColor: yellow[50],
  },
});
const bannerswipeable = 'bannerswipeable';
const widgetTypes = [
  {
    type: 'banner',
    name: 'แบนเนอร์',
    description: 'รูปภาพขนาดใหญ่',
  },
  {
    type: bannerswipeable,
    name: 'แบนเนอร์สไลเดอร์',
    description: 'กลุ่มรูปภาพขนาดใหญ่',
  },
  {
    type: 'title',
    name: 'หัวข้อ',
    description: 'ข้อความขนาดใหญ่สำหรับหัวข้อ',
  },
  {
    type: 'text',
    name: 'เนื้อหา',
    description: 'ข้อมูลประเภทเนื้อหา ใส่ได้หลายบรรทัด',
  },
  {
    type: 'link',
    name: 'ลิงค์ภายนอก',
    description: 'รายการลิงค์',
  },
  {
    type: 'contact',
    name: 'ที่ติดต่อ',
    description: 'รายการที่ติดต่อ',
  },
  {
    type: 'appointment',
    name: 'นัดตรวจออนไลน์',
    description: 'รายการนัดตรวจออนไลน์',
  },
  {
    type: 'file',
    name: 'ไฟล์ประกอบ',
    description: 'รายการไฟล์สำหรับดาวน์โหลด',
  },
  {
    type: 'post',
    name: 'บทความ',
    description: 'รายการบทความ',
  },
  {
    type: 'mapwithcontact',
    name: 'แผนที่ที่ติดต่อ',
    description: 'แผนที่สำหรับรายการติดต่อ',
  },
  {
    type: 'aboutusmap',
    name: 'แผนที่เกี่ยวกับเรา',
    description: 'แผนที่สำหรับหน้าเกี่ยวกับเรา',
  },
  {
    type: 'contactus',
    name: 'ฟอร์มติดต่อเรา',
    description: 'แบบฟอร์มติดต่อเรา',
  },
  {
    type: 'hotmenu',
    name: 'เมนูลัด',
    description: 'เมนูลัดเข้าสู่หน้าที่เข้าใช้บ่อย',
  },
  {
    type: 'hilight',
    name: 'ไฮไลท์',
    description: 'เมนูเด่นที่แนะนำ',
  },
  {
    type: 'video',
    name: 'วิดีโอ',
    description: 'วิดีโอ',
  },
  {
    type: 'hotmenu-teenage',
    name: 'เมนูลัดหน้า Teenage',
    description: 'เมนูลัดเข้าสู่หน้าที่เข้าใช้บ่อยสำหรับหน้า Home Teenage',
  },
  {
    type: 'hotmenu-transgender',
    name: 'เมนูลัดหน้า Transgender',
    description: 'เมนูลัดเข้าสู่หน้าที่เข้าใช้บ่อยสำหรับหน้า Home Transgender',
  },
  {
    type: 'hotmenu-msm',
    name: 'เมนูลัดหน้า MSM',
    description: 'เมนูลัดเข้าสู่หน้าที่เข้าใช้บ่อยสำหรับหน้า Home MSM',
  },
];

const ContentPage = ({ history }) => {
  const [blocks, setBlocks] = useState([
    {
      type: 'title',
      content: 'This is title #1',
    },
    {
      type: 'content',
      content: '',
    },
    {
      type: 'contact',
      content: '',
    },
  ]);
  const [selectedType, setSelectedType] = useState('none');
  const [showDialog, setShowDialog] = useState(false);
  const [dialogPayload, setDialogPayload] = useState({ block: {} });
  const [snackbar, setSnackbar] = useState('');
  const [contacts, setContacts] = useState([]);
  const [links, setLinks] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [posts, setPosts] = useState([]);
  const [files, setFiles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmData, setConfirmData] = useState({
    content: '',
    open: false,
    onOk: () => {},
    onClose: () => {},
  });
  const { paperRoot } = useStyles();

  const getPage = useCallback(
    () => history.location.pathname.substring(1),
    [history.location.pathname]
  );

  const fetch = useCallback(async () => {
    setLoading(true);
    const promises = [
      PageService.fetchDev(getPage()),
      CloudService.fetchAll('contacts'),
      CloudService.fetchAll('links'),
      CloudService.fetchAll('appointments'),
      CloudService.fetchAll('posts'),
      CloudService.fetchAll('files'),
      CloudService.fetchAll('videos'),
    ];
    const [page, contacts, links, appointments, posts, files, videos] =
      await Promise.all(promises);
    setBlocks(page.blocks || []);
    setContacts(contacts);
    setLinks(links);
    setAppointments(appointments);
    setPosts(posts);
    setFiles(files);
    setVideos(videos);
    setLoading(false);
  }, [getPage]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleAdd = () => {
    const added = [...blocks, { type: selectedType, content: '' }];
    setBlocks(added);
    setSelectedType('none');
  };
  const handleItemClick = (index) => {
    setDialogPayload({ index, block: blocks[index] });
    setShowDialog(true);
  };
  const handleOpenConfirmDeleteBlock = (index) => {
    setConfirmData({
      open: true,
      content: 'Confirm to Delete?',
      onOk: () => handleDeleteBlock(index),
      onClose: () => setConfirmData({ open: false }),
    });
  };
  const handleDeleteBlock = (index) => {
    setConfirmData({ open: false });
    let updateBlocks = [...blocks];
    updateBlocks.splice(index, 1);
    setBlocks(updateBlocks);
  };

  const handleSaveBlock = (index, block) => {
    let data = [...blocks];
    data[index] = block;
    setBlocks(data);
    setShowDialog(false);
  };
  const save = async () => {
    let promises = [];
    let blockList = blocks;
    blockList.forEach((block) => {
      if (block.type === bannerswipeable && block.content) {
        promises = [...promises, uploadBannerSwipeableFiles(block)];
      } else if (block.file) {
        promises = [
          ...promises,
          StorageService.upload(`${getPage()}`, block.file),
        ];
      } else {
        promises = [...promises, null];
      }
    });
    const resArr = await Promise.all(promises);
    resArr.forEach((res, index) => {
      if (res) {
        if (blockList[index].type === bannerswipeable) {
          blockList[index] = managerBannerSwipeableFiles(blockList[index], res);
        } else {
          blockList[index].content.image = res;
          delete blockList[index].file;
        }
      }
    });
    await PageService.save({ id: getPage(), blocks: blockList });
    setSnackbar('บันทึกข้อมูลสำเร็จ');
  };

  const uploadBannerSwipeableFiles = (block) => {
    let promises = [];
    block.content.forEach((item) => {
      if (item.file) {
        promises = [
          ...promises,
          StorageService.upload(`${getPage()}`, item.file),
        ];
      } else {
        promises = [...promises, null];
      }
    });
    return Promise.all(promises);
  };

  const managerBannerSwipeableFiles = (block, responses) => {
    responses.forEach((res, index) => {
      if (res) {
        block.content[index].image = res;
        delete block.content[index].file;
      }
    });
    return block;
  };

  if (loading) return <div>Loading...</div>;
  return (
    <>
      <Container maxWidth="md">
        <Box
          component={Paper}
          alignItems="center"
          display="flex"
          p={1}
          m={2}
          classes={{
            root: paperRoot,
          }}
        >
          <Box component={Error} mr={1} />
          คลิกที่กล่องข้อมูลเพื่อแก้ไข หรือคลิกค้างแล้วลาก เพื่อสลับตำแหน่ง
        </Box>
        <Box display="flex" alignItems="center" margin={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="widget-type">ประเภท</InputLabel>
            <Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <MenuItem value={'none'}>
                <em>กรุณาเลือก</em>
              </MenuItem>
              {widgetTypes.map((widgetType, index) => (
                <MenuItem key={index} value={widgetType.type}>
                  {widgetType.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>เลือกประเภทของกล่องข้อมูลที่ต้องการ</FormHelperText>
          </FormControl>
          <Box p={1} />
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleAdd}
            disabled={selectedType === 'none'}
          >
            เพิ่ม
          </Button>
        </Box>
        <WidgetList
          blocks={blocks}
          setBlocks={setBlocks}
          onItemClick={handleItemClick}
          onDelete={handleOpenConfirmDeleteBlock}
        />
        <Box display="flex" justifyContent="flex-end" margin={2}>
          <Button variant="contained" color="primary" onClick={save}>
            บันทึกข้อมูล
          </Button>
        </Box>
      </Container>
      <ContentDialog
        type={widgetTypes.find((w) => w.type === dialogPayload.block.type)}
        contacts={contacts}
        links={links}
        appointments={appointments}
        posts={posts}
        files={files}
        videos={videos}
        payload={dialogPayload}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onSave={handleSaveBlock}
      />
      <Snackbar
        open={snackbar !== ''}
        message={snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar('')}
        color=""
      />
      <ConfirmDialog
        title="Confirm"
        content={confirmData.content}
        open={confirmData.open}
        onOk={confirmData.onOk}
        onClose={confirmData.onClose}
      />
    </>
  );
};

export default ContentPage;
