import React, { useState } from 'react';
import { Avatar, Box, Paper, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Mail, Edit, Delete } from '@material-ui/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const useStyles = makeStyles({
  widget: {
    opacity: ({ sorting }) => sorting && 0.5,
    cursor: 'pointer',
  },
});

const Widget = SortableElement(
  ({ blockIndex, block, sorting, onClick, onDelete }) => {
    const { widget } = useStyles({ sorting });
    return (
      <Box className={widget} component={Paper} display="flex" m={2} p={1}>
        <Box p={1}>
          <Avatar>
            <Mail />
          </Avatar>
        </Box>
        <Box width={'80%'}>
          <Typography variant="body1" noWrap>
            {block.type}
          </Typography>
          <Typography variant="caption" component="p" noWrap>
            {JSON.stringify(block.content)}
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              onClick(blockIndex);
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              onDelete(blockIndex);
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  }
);

const WidgetContainer = SortableContainer(
  ({ blocks, sorting, onItemClick, onDelete }) => {
    return (
      <div>
        {blocks.map((block, index) => (
          <Widget
            key={index}
            index={index}
            blockIndex={index}
            block={block}
            sorting={sorting}
            onClick={onItemClick}
            onDelete={onDelete}
          />
        ))}
      </div>
    );
  }
);

export default ({ blocks, setBlocks, onItemClick, onDelete }) => {
  const [sorting, setSorting] = useState(false);
  const onSortStart = () => setSorting(true);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSorting(false);
    setBlocks(arrayMove(blocks, oldIndex, newIndex));
  };
  return (
    <WidgetContainer
      pressDelay={500}
      blocks={blocks}
      sorting={sorting}
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      onItemClick={onItemClick}
      onDelete={onDelete}
    />
  );
};
