import React, { useEffect, useState, useRef } from 'react';
import { Snackbar } from '@material-ui/core';
import { database, fieldValue } from 'common/firebase';
import PostList from '../components/PostList';
import ConfirmDialog from '../components/ConfirmDialog';
import StorageService from 'common/services/storage';

const ManagePost = () => {
  const [posts, setPosts] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [snackbar, setSnackbar] = useState('');
  let deletingPost = useRef();

  useEffect(() => {
    database
      .collection('posts')
      .orderBy('createdAt')
      .get()
      .then(snapshot => {
        const posts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(posts);
      });
  }, [lastUpdate]);

  const handelDelete = post => {
    deletingPost.current = post;
    setShowConfirm(true);
  };

  const handleSave = async post => {
    // add createdAt & updatedAt field to the object
    if (!post.createdAt) post.createdAt = fieldValue.serverTimestamp();
    post.updatedAt = fieldValue.serverTimestamp();

    if (post.imageFile) {
      const url = await StorageService.upload(
        `posts/images`,
        post.imageFile.file
      );
      post[post.imageFile.key] = url;
      delete post.imageFile;
    }
    let updatedPosts = [...posts];
    if (!post.id) {
      const res = await database.collection('posts').add(post);
      post.id = res.id;
      updatedPosts = [...updatedPosts, post];
    } else {
      database
        .collection('posts')
        .doc(post.id)
        .set(post);
      updatedPosts[updatedPosts.findIndex(p => p.id === post.id)] = post;
    }
    setPosts(updatedPosts);
    setLastUpdate(new Date().getSeconds());
    setSnackbar('บันทึกข้อมูลสำเร็จ');
  };

  const confirmDelete = () => {
    database
      .collection('posts')
      .doc(deletingPost.current.id)
      .delete()
      .then(() => {
        setSnackbar('ลบข้อความแล้ว');
        setLastUpdate(new Date().getSeconds());
      });
    setShowConfirm(false);
  };

  if (!posts) return null;

  return (
    <>
      <PostList posts={posts} onDelete={handelDelete} onSave={handleSave} />
      <ConfirmDialog
        open={showConfirm}
        title="ยืนยันการลบบทความ"
        content="การกระทำนี้ ไม่สามารถย้อนกลับได้"
        onOk={confirmDelete}
        onClose={() => setShowConfirm(false)}
      />
      <Snackbar
        open={snackbar !== ''}
        autoHideDuration={3000}
        message={snackbar}
        onClose={() => setSnackbar('')}
      />
    </>
  );
};

export default ManagePost;
