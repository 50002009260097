export const icons = [
  {
    name: 'about-us',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-about-us.png?alt=media&token=d37ebe52-0b92-4460-96f2-324d26bc8db1'
  },
  {
    name: 'annouce',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-annouce.png?alt=media&token=ca72ab1f-24cc-4c43-8a38-d78758489247'
  },
  {
    name: 'condom',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-condom.png?alt=media&token=61d27afa-1296-442c-bcbb-ccda18e97d38'
  },
  {
    name: 'ds',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-ds.png?alt=media&token=61dbfa5c-39e9-4166-903c-b86b8b33cc31'
  },
  {
    name: 'hospital',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-hospital.png?alt=media&token=891d4841-9ce8-43f8-8298-8286e2060aa1'
  },
  {
    name: 'medical place',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-medical-place.png?alt=media&token=9493a0f2-62cc-452a-87d0-0a5bdd4c7b83'
  },
  {
    name: 'mental Health',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-mentalHealth.png?alt=media&token=4728c0e9-99d0-4088-8128-c0251e6ef16a'
  },
  {
    name: 'prep-pep',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-prep.png?alt=media&token=e27cdcad-4429-4489-83f0-17028a2bce2d'
  },
  {
    name: 'ribbon',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-ribbon.png?alt=media&token=6d80864c-337d-41cc-a0c8-f97a8dea395e'
  },
  {
    name: 's and d',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-s-d.png?alt=media&token=14d41e95-aba6-47c8-89c3-d856691e29df'
  },
  {
    name: 'tb',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-tb.png?alt=media&token=feb41258-0c1f-47bd-9f54-b7182225c8a7'
  },
  {
    name: 'transgender',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-transgender.png?alt=media&token=73873e86-df7d-4fcd-b18b-e4fcd743e343'
  },
  {
    name: 'search',
    id:
      'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/icons%2Ficon-search.png?alt=media&token=64252190-5d64-45ab-a9c3-f2567bcfc51f'
  },
  {
    name: 'library_books',
    id: 'library_books'
  },
  {
    name: 'save_alt',
    id: 'save_alt'
  }
];
