import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import { auth } from 'common/firebase';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import ContentPage from './screens/ContentPage';
import Scaffold from './components/Scaffold';
import ManageItem from './screens/ManageItem';
import ManagePost from './screens/ManagePost';

import {
  contactFields,
  appointmentFields,
  linkFields,
  fileFields,
  videoFields
} from './configs/formSchema';

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState<boolean>();

  useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });
  }, []);

  switch (authenticated) {
    case true:
      return (
        <Scaffold>
          <Switch>
            <Route path="/" component={Dashboard} exact />
            <Route
              path="/manage-contact"
              render={route => (
                <ManageItem
                  {...route}
                  collection="contacts"
                  formFields={contactFields}
                />
              )}
              exact
            />
            <Route
              path="/manage-link"
              render={route => (
                <ManageItem
                  {...route}
                  collection="links"
                  formFields={linkFields}
                />
              )}
              exact
            />
            <Route
              path="/manage-appointment"
              render={route => (
                <ManageItem
                  {...route}
                  collection="appointments"
                  formFields={appointmentFields}
                />
              )}
              exact
            />
            <Route
              path="/manage-file"
              render={route => (
                <ManageItem
                  {...route}
                  collection="files"
                  formFields={fileFields}
                />
              )}
              exact
            />
             <Route
              path="/manage-video"
              render={route => (
                <ManageItem
                  {...route}
                  collection="videos"
                  formFields={videoFields}
                />
              )}
              exact
            />
            <Route path="/manage-post" component={ManagePost} />
            <Route path="/" component={ContentPage} />
          </Switch>
        </Scaffold>
      );
    case false:
      return <Login />;
    default:
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={'100vh'}
        >
          <CircularProgress />
        </Box>
      );
  }
}

export default App;
