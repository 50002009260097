import mapService from 'common/services/map';

export const sampleFields = [
  { name: 'title', label: 'ชื่อ', type: 'string' },
  {
    name: 'select',
    label: 'เลือกตัวเลือก',
    type: 'select',
    options: [
      { label: 'ตัวเลือกที่ 1', value: '1' },
      { label: 'ตัวเลือกที่ 2', value: '2' },
      { label: 'ตัวเลือกที่ 3', value: '3' }
    ]
  },
  {
    name: 'detail',
    label: 'รายละเอียด',
    type: 'string',
    multiline: true,
    fullWidth: true
  },
  { name: 'image', label: 'เลือกรูปภาพ', type: 'image', file: 'file' },
  { name: 'document', label: 'เลือกไฟล์', type: 'file', file: 'attachment' },
  { name: 'text', label: 'บทความ', type: 'text', fullWidth: true }
];

export const contactFields = [
  { name: 'title', label: 'ชื่อ', type: 'string' },
  { name: 'detail', label: 'รายละเอียด', type: 'string' },
  {
    name: 'address',
    label: 'ที่อยู่',
    type: 'string',
    fullWidth: true
  },
  {
    name: 'district',
    label: 'เขต',
    type: 'select',
    options: mapService
      .districts()
      .map(district => ({ label: district.text, value: district.text }))
  },
  {
    name: 'image',
    label: 'โลโก้',
    type: 'image',
    file: 'imageFile',
    fullWidth: true
  },
  { name: 'hours', label: 'เวลาทำการ', type: 'string' },
  {
    name: 'overtime',
    label: 'บริการนอกเวลา',
    type: 'select',
    options: [{ label: 'มี', value: 'yes' }, { label: 'ไม่มี', value: '-' }]
  },
  { name: 'contact', label: 'ติดต่อ', type: 'string' }, // readable format like 01-111-1111
  { name: 'tel', label: 'เบอร์โทรศัพท์', type: 'string' }, // for calling e.g. 011111111
  { name: 'contactPerson', label: 'ชื่อผู้ประสานงาน', type: 'string' },
  { name: 'train', label: 'รถไฟฟ้า', type: 'string' },
  { name: 'email', label: 'อีเมล', type: 'string' },
  { name: 'website', label: 'เวบไซต์', type: 'string' },
  { name: 'facebook', label: 'Facebook', type: 'string' },
  { name: 'latitude', label: 'ละติจูด', type: 'string' },
  { name: 'longitude', label: 'ลองจิจูด', type: 'string' },
  {
    name: 'extra',
    label: 'Extra Tag',
    type: 'string',
    fullWidth: true,
    tips: 'y=เยาวชน m=ชายรักชาย t=คนข้ามเพศ'
  }, //y=young(teen), m=biosexual, t=transgender
  { name: 'tag', label: 'แท็ก', type: 'string', fullWidth: true },
  {
    name: 'privilege',
    label: 'สิทธิการรักษา',
    type: 'string',
    fullWidth: true,
    tips: 'sso=สิทธิ์ประกันสังคม of=สิทธิ์ข้าราชการ gold=สิทธิ์บัตรทอง'
  }
];

export const linkFields = [
  { name: 'title', label: 'ชื่อ', type: 'string', fullWidth: true },
  { name: 'detail', label: 'รายละเอียด', type: 'string', fullWidth: true },
  {
    name: 'image',
    label: 'โลโก้',
    type: 'image',
    file: 'imageFile',
    fullWidth: true
  },
  { name: 'url', label: 'ลิงค์', type: 'string', fullWidth: true },
  { name: 'tag', label: 'แท็ก', type: 'string', fullWidth: true }
];

export const appointmentFields = [
  { name: 'title', label: 'ชื่อ', type: 'string', fullWidth: true },
  { name: 'url', label: 'ลิงค์', type: 'string', fullWidth: true },
  {
    name: 'image',
    label: 'โลโก้',
    type: 'image',
    file: 'imageFile',
    fullWidth: true
  },
  { name: 'tag', label: 'แท็ก', type: 'string', fullWidth: true }
];

export const fileFields = [
  { name: 'title', label: 'ชื่อ', type: 'string', fullWidth: true },
  {
    name: 'image',
    label: 'โลโก้',
    type: 'image',
    file: 'imageFile',
    fullWidth: true
  },
  {
    name: 'fileLink',
    label: 'ไฟล์สื่อ',
    type: 'file',
    file: 'file',
    fullWidth: true
  },
  { name: 'tag', label: 'แท็ก', type: 'string', fullWidth: true }
];

export const postFileds = [
  { name: 'title', label: 'หัวข้อบทความ', type: 'string', fullWidth: true },
  {
    name: 'image',
    label: 'รูปหน้าปก',
    type: 'image',
    file: 'imageFile',
    fullWidth: true
  },
  { name: 'video', label: 'วิดีโอหน้าปก', type: 'string', fullWidth: true },
  {
    name: 'excerpt',
    label: 'เนื้อหาโดยย่อ',
    type: 'string',
    multiline: true,
    fullWidth: true
  },
  { name: 'content', label: 'บทความ', type: 'text', fullWidth: true }
];

export const videoFields = [
  { name: 'title', label: 'ชื่อ', type: 'string', fullWidth: true },
  { name: 'detail', label: 'รายละเอียด', type: 'string', fullWidth: true },
  {
    name: 'image',
    label: 'ภาพปก (หากไม่ระบุ จะใช้ภาพจาก YouTube)',
    type: 'image',
    file: 'imageFile',
    fullWidth: true
  },
  { name: 'url', label: 'ลิงค์', type: 'string', fullWidth: true }
];
